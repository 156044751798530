<template>  
    <v-list>  
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-content>
          <v-list-item-title>
            <a :href="item.link" target="_blank">{{ item.title }}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>  
  </template>

<script>
export default {
    name: "Result",
    data() {
        return {
          items: [
            { title: '三国演义武评结果', link: 'https://www.zhihu.com/question/521940059/answer/3264616239' },
            { title: '三国演义智评结果', link: 'https://www.zhihu.com/question/372483549/answer/3421676289' },
            { title: '三国演义武将智评结果', link: 'https://www.zhihu.com/question/556209627/answer/3339697787' },
            { title: '水浒传武评结果', link: 'https://www.zhihu.com/question/21441647/answer/3511359686' }
          ]
        }
    },
    created() {
    },
    methods: {
    }
};
</script>

<style scoped></style>